$primary: #a890bb;
$darkPrimary: #512178;
$darkerPrimary: #55277b;
$lessDarkPrimary: #6d3899;
$lighterGray: #e1e1e1;
$lightGray: #adadad;
$dashboard: #f0f0f7;
$red: #c33030;
$large: 700px;
$medium: 500px;

body {
    background-color: #f0f0f7 !important;
}

/* Remove arrow of number inputs Chrome, Safari, Edge, Opera*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

[type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid #55277b;
    border-bottom: 2px solid #55277b;
}

/* Remove arrow of number inputs Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

#tablePlanejamentoEducacional {
    td {
        font-size: 17px;
        height: 50px;
    }
}

.space-top-bottom {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
    header,
    .main,
    footer {
        padding-left: 0px !important;
    }
}

.btn {
    text-transform: none !important;
    border-radius: 5px;
    &.primary {
        background-color: $darkPrimary;
        height: 43px;
    }

    &.downloadPdf {
        margin-top: 25px;
        padding-top: 2px;
    }

    &.btn-steps {
        width: 100px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.space-down-up {
        margin: 30px 0px;
    }
    &.danger {
        background-color: #ff5252;
        margin-top: 30px;
    }
    &.btn-steps-back {
        background-color: white;
        color: #512178;
        margin-left: 60px;
    }
    &.btn-steps-next {
        margin-right: 60px;
    }
    &.btn-with-img {
        .delete-button-txt {
            position: relative;
            bottom: 4px;
        }
        .delete-button-img {
            svg {
                width: 25px;
                height: 25px;
                margin-right: 10px;
                position: relative;
                top: 4px;
            }
        }
    }
}

a {
    color: $darkPrimary;
    font-weight: normal;
    text-decoration: none;
}

input[type="text"] + label {
    pointer-events: none;

    * {
        pointer-events: all;
    }
}

.checkbox {
    border: solid 1px rgba(0, 0, 0, 0.116);
    padding: 20px 70px;
    border-radius: 5px;
}

.checkboxSeguros {
    border: solid 1px rgba(0, 0, 0, 0.116);
    // margin: 20px 0px;
    border-radius: 5px;
    height: 60px;
    position: relative;
    span {
        color: #54536d;
        font-size: 17px;
        position: relative;
    }
}

.notasInput {
    #nota {
        min-height: 160px;
        padding-top: 10px;
        margin-left: 6px;
        padding-left: 10px;
    }
    label {
        padding-left: 10px;
        font-size: 16px;
    }
}

.notasAndAtividadesInput {
    #nota {
        min-height: 100px;
        padding-top: 10px;
        margin-left: 6px;
        padding-left: 10px;
    }
    label {
        padding-left: 10px;
        font-size: 16px;
    }
}

.input-field {
    input,
    textarea {
        border: 1px solid rgba(0, 0, 0, 0.116) !important;
        padding-left: 10px !important;
        border-radius: 5px !important;
        color: #532677;
        textarea {
            width: 100%;
        }
    }
    label {
        color: #6d3899a9;
        margin-left: 10px;
    }
    label:not(.label-icon).active {
        transform: translateY(-20px) scale(0.8);
        transform-origin: 0 0;
    }
    .select {
        font-size: 1rem;
        transform: translateY(-20px) scale(0.8) !important;
        transform-origin: 0 0;
        top: 0;
    }
    .select-wrapper {
        .select-dropdown li.disabled,
        .select-dropdown li.disabled > span,
        .select-dropdown li.optgroup {
            color: #6d3899a9(0, 0, 0, 0.3);
            background-color: transparent;
        }
        .dropdown-content li > a,
        .dropdown-content li > span {
            font-size: 16px;
            color: #6d3899a9;
            display: block;
            line-height: 22px;
            padding: 14px 16px;
        }
    }
    &:active,
    &:focus-within {
        label {
            transform: translateY(-23px) scale(1) !important;
            transform-origin: 0 0;
            color: #6d3899a9 !important;
        }
        input {
            box-shadow: 1px 1px 5px #8b63ac34 !important;
        }
    }
}

.input-field-date-registration {
    margin-bottom: 1rem;

    label {
        transform: translateY(-14px) scale(0.8);
        transform-origin: 0 0;
        position: relative;
    }
}

form {
    .submit {
        // display: flex;
        // align-items: center;
        // justify-content: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 150px;
            height: 40px;
            line-height: 15px;
        }
    }

    small {
        margin-top: 30px 0px;
        height: 40px;
        display: block;
        text-align: center;

        a {
            font-size: 1.2em;
            font-weight: normal;
            text-transform: none;

            span {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
}

.bg-login {
    background-image: url("./Assets/Images/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    @media (max-width: 1200px) {
        display: none;
    }
}

.m0 {
    margin: 0;
}

.p20 {
    padding: 2em;
}

.preloader-wrapper {
    // display: block;
    margin: 0 auto;
    .spinner-purple {
        border-color: $lessDarkPrimary !important;
    }
    .spinner-layer {
        border-color: #fff;
    }
}

.logo-lab {
    background-image: url("./Assets/Images/logo.png");
    background-size: cover;
    @media (min-width: 1200px) {
        position: fixed;
        top: 5%;
        left: 3%;
        width: 100px;
        height: 64px;
    }
    @media (max-width: 1200px) {
        position: relative;
        left: 50%;
        margin-left: -100px;
        margin-bottom: 10%;
        margin-top: 0%;
        width: 250px;
        height: 160px;
    }
    @media (max-width: $large) {
        position: relative;
        left: 50%;
        margin-left: -100px;
        margin-bottom: 10%;
        margin-top: 0%;
        width: 200px;
        height: 128px;
    }
    @media (max-width: $medium) {
        position: relative;
        left: 50%;
        margin-left: -100px;
        margin-bottom: 10%;
        margin-top: -5%;
        width: 200px;
        height: 128px;
    }
}
.form-login {
    max-width: 500px;
    margin: 0 auto;
    @media screen and (min-width: $large) {
        max-width: 500px;
    }
    @media screen and (max-width: $large) {
        max-width: 400px;
    }
    @media screen and (max-width: $medium) {
        max-width: 320px;
    }
    .primary {
        margin: 20px 0px;
    }
    form.criar-conta {
        .checkbox {
            margin-bottom: 0em;
        }
    }
    .checkbox-login {
        color: #512178 !important;
    }
    .login {
        &.criar-conta {
            h1 {
                margin-bottom: 1em;
            }
        }
        h1 {
            margin-bottom: 1em;
        }
        h2 {
            font-weight: bold;
            color: $darkPrimary;
            font-size: 2em;
            margin-bottom: 0.25em;
            margin-top: 0;
        }
        h3 {
            color: $lightGray;
            font-weight: normal;
            margin: 0 0 2em;
            font-size: 1em;
        }
    }

    .align-text-right {
        text-align: right;
    }
}

.pl0 {
    padding-left: 0 !important;
}

.content {
    // background-color: $dashboard;
    h3 {
        font-size: 1.8em;
        font-weight: bold;
    }
    h4 {
        font-size: 1.3em;
        font-weight: bold;
    }
    &.primeiraReuniao,
    &.listaCliente,
    &.listaNegocios {
        // h2 {
        //     font-size: 2.2em;
        //     margin: 0 0 1em !important;
        // }
        .container-passos {
            max-width: 1000px;
            width: 90%;
            // padding: 5px 5%;
            margin: 0% auto 5% auto;
            // border-radius: 10px;
            // background-color: white;
            .margin-top {
                margin-top: 40px;
            }
            form {
                margin: 30px auto 30px auto;
                border-radius: 10px;
                background-color: white;
                // padding: 2% 5%;
                padding: 1.5% 5% 1.5% 5%;
            }

            &.pr-simplificada {
                .divider-top {
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
                .divider-bottom {
                    margin-top: 10px;
                    margin-bottom: 30px;
                }
                #administradora-bens {
                    position: relative;
                    bottom: 6px;
                }
                .options-tipo-calc {
                    color: #5d5c74;
                }
            }
        }
        .container-table {
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            display: block;

            .content-table {
                // max-width: 800px;
                padding: 20px;
                border-radius: 10px;
                background-color: white;

                td {
                    padding: 10px 5px;
                }
                .openPerfil {
                    cursor: pointer;
                }
            }

            .actions {
                // text-align: center;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                .btn {
                    margin-left: 10px;
                    font-size: 13px;
                    font-weight: bold;
                    padding-top: 1px;
                }
                .tooltipped {
                    display: flex;
                    i {
                        margin-left: 15px;
                    }
                }
            }

            .hearder-lists {
                margin-top: 20px;

                display: flex;
                align-items: center;
                .card-list-values {
                    max-width: 300px;
                    background-color: white;
                    border-radius: 10px;
                    height: 65px;
                    margin-left: auto;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    position: relative;
                    left: 12px;

                    .card-list-value-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .material-icons {
                            margin-right: 8px;
                        }
                    }
                    h4 {
                        // line-height: 110%;
                        position: relative;
                        bottom: 2px;
                        font-size: 20px;
                    }
                }
            }
        }
        .component-current-step-buttons {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
            .btn {
                // width: 50%;
                box-shadow: none;
                border: 1px solid #512178;
            }
            .primary {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .passos {
            margin-bottom: 3em;
            display: flex;

            span {
                color: $darkerPrimary;
                //flex: auto;
                padding: 1em 2em;
                cursor: pointer;
                border-bottom: 1px solid $darkPrimary;
                font-weight: 600;
                //text-align: center;
                &.active,
                &:hover {
                    background-color: $darkPrimary;
                    color: #fff;
                }
            }
        }

        .passo {
            display: none;
            background-color: #fff;
            padding: 2.1em 2em;
            margin-bottom: 2em;
            border-radius: 0.25em;
            // -webkit-box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.1);
            // box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.1);
            h3,
            h4 {
                margin-top: 0;
            }

            &.active {
                display: block;
            }
        }

        small {
            margin-top: 12px;
            height: 10px;
            display: block;
            text-align: center;
            margin-bottom: 25px;

            a {
                font-size: 1.2em;
                font-weight: normal;
                text-transform: none;
                cursor: pointer;

                span {
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
        }
        .independencia-client-infos {
            // margin: 20px 0px;
            margin-top: 50px;
            margin-bottom: 15px;
        }
        .container-steps {
            color: #43425d;
            margin-bottom: 20px;
            &.header {
                padding-bottom: 40px;
                .row {
                    margin-top: 40px;
                }
            }
            h2 {
                font-weight: bold;
                font-size: 22px;
                // margin: 20px 0px 20px 0px;
            }
            h3 {
                font-size: 15px;
                font-weight: 500;
                // margin: 0px 0px 15px 0px;
            }
            h4 {
                font-size: 15px;
                font-weight: 500;
            }
            .input-field {
                top: 7px;
                right: 6px;
            }

            .container-tipo-aposentadoria {
                border: #e4e4e4 solid 1px;
                border-radius: 5px;
                margin-top: 20px;
                .header-tipo-aposentadoria {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    bottom: 12px;
                    background-color: white;
                }
            }
            .container-tipo-calc-aposentadoria {
                border: #e4e4e4 solid 1px;
                border-radius: 5px;
                margin-top: 45px;
                .checkOptions {
                    line-height: 15px;
                }
                .header-tipo-aposentadoria {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    bottom: 12px;
                    background-color: white;
                }
                .header-renda-desejada {
                    margin-bottom: 25px;
                    margin-top: 30px;
                }
                .options-tipo-calc {
                    font-size: 14px;
                    color: #43425d;
                }
                .calc-prazo {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
            .button-simulated-calc {
                position: relative;
                a {
                    background-color: #34c38f !important;
                    z-index: 2 !important;
                }
                hr.simulated-calc {
                    border-top: 1px solid #f0f1f2;
                    position: relative;
                    top: 14px;
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 45px;
                    svg {
                        width: 35px;
                        height: 35px;
                        position: relative;
                        right: 10px;
                        fill: white !important;
                    }
                }
            }
            .expenses-dependent {
                .col,
                input {
                    margin: 0;
                }
                .header-expenses {
                    margin-bottom: 10px;
                }
                .description-expenses {
                    margin-top: 20px;
                    margin-bottom: 9px;
                    h3 {
                        margin: 0px;
                    }
                    // margin: 0 !important;
                    // padding: 0 !important;
                }
                .button-add-expense {
                    position: relative;
                    margin: 20px 0px;
                    padding: 0px;
                    .btn {
                        padding: 0;
                        margin: 0;
                    }
                    a {
                        background-color: #34c38f !important;
                        font-size: 12px;
                    }
                    hr.simulated-calc {
                        border-top: 1px solid #f0f1f2;
                        position: relative;
                        top: 17px;
                    }
                }
                input {
                    // padding-left: 15px;
                    border: none;
                    box-shadow: none !important;
                }
                .input-expenses-dependent {
                    //  padding-left: 0 0.75rem;
                    border: #f0f0f0 2px solid;
                    border-radius: 2px;
                }
                .delete-expense {
                    position: relative;
                    top: 8px;
                    left: 10px;
                }
            }

            .container-percent-values {
                margin: 30px 0px;
            }
            .switch-independencia,
            .lever {
                bottom: 2px;
            }
            .switch label input[type="checkbox"]:checked + .lever:after {
                background-color: #512178;
            }
            .switch label .lever:after {
                background-color: #caaae4;
            }
            .switch label input[type="checkbox"]:checked + .lever {
                background-color: #dacce6;
            }
            .switch label .lever {
                background-color: #dacce6;
            }
            [type="radio"]:checked + span:after,
            [type="radio"].with-gap:checked + span:after {
                background-color: #512178;
                border: none;
            }
            [type="radio"]:not(:checked) + span:before,
            [type="radio"]:not(:checked) + span:after {
                border: 2px solid #512178;
            }
            [type="checkbox"].filled-in:checked + span:not(.lever):after {
                border: 2px solid #512178;
                background-color: #512178;
            }
        }
        .card-simulated-calc {
            height: 140px;
            padding: 5% 2%;
            // display: block;
            svg {
                width: 35px;
                height: 35px;
                // position: relative;
                // bottom: 133px;
                // background-color: rgb(255, 255, 255);
                // border-radius: 50%;
            }
            .dot-back {
                height: 47px;
                width: 47px;
                background-color: white;
                border-radius: 50%;
                position: relative;
                bottom: 138px;

                // bottom: 133px;
            }
            .content-card-simulated-calc {
                border: rgba(0, 0, 0, 0.116) solid 1px;
                // background-color: #a3a0fb;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                height: 110px;
                padding: 10px;
                padding-top: 13px;
            }
            .value-simulated-calc {
                font-weight: bold;
                margin-top: 3px;
                font-size: 18px;
            }
        }
    }

    .dashboard {
        // background-color: white;
        .header-dashboard {
            // background-color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            height: 80px;
        }
        .noBold {
            font-weight: normal !important;
        }
        #daysToEndMonth {
            font-weight: bold;
        }
        .overlayChart {
            display: block;
            text-align: center;
            position: absolute;
            width: 110px;
            height: 110px;
            margin-top: 38px;
            //top: 0px;  /* chartArea top  */
            //left: 0px; /* chartArea left */
            font-size: 22px;
            //margin-top: 74px;
            //margin-left: 54px;
            font-weight: bold;
            color: white;
        }

        .rowCards {
            //width: 1350px;
            max-width: 1350px;
            // margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-between;
            display: flex;
            //column-gap: 50px;
            &.smallScreen {
                //width: 700px;
                //min-width: 700px;
                flex-wrap: wrap;
                .twoColumns {
                    //width: 700px;
                    margin-top: 50px;
                }
                @media (max-width: 1100px) {
                    //width: 630px;
                    //min-width: 630px;
                }
            }
        }
        .dashboardsCards {
            align-content: flex-start;
            display: flex;
            gap: 50px;
            flex-wrap: wrap;

            &.oneColumn {
                max-width: 300px;
            }
            &.twoColumns {
                max-width: 650px;
                justify-content: space-between;
            }
            &.lastRow {
                margin-bottom: 100px;
                margin-top: 50px;
                .bigCardLastRow {
                    max-width: 500px;
                    height: 450px;
                    .funilComercial {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 460px !important;
                        height: 400px !important;
                    }
                }
                .soBigCardLastRow {
                    max-width: 800px;
                    height: 450px;
                    .topComercial {
                        max-width: 700px;
                        height: 360px;
                        overflow-y: auto;
                        margin-left: auto;
                        margin-right: auto;
                        table {
                            height: 360px;
                        }
                        .assessorUnity {
                            display: flex;
                            align-items: center;
                            .nameAssessor {
                                padding-left: 20px;
                                font-weight: bold;
                            }
                            i {
                                padding-left: 20px;
                            }
                        }
                    }
                    .centerText {
                        text-align: center;
                    }
                }
            }

            .smallCard {
                //min-width: 300px;
                max-width: 300px;
                height: 150px;

                .chartAndValue {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }

                .displayBlock {
                    display: block;
                }
            }
            .twoSmall {
                display: flex;
                flex-wrap: wrap;
                flex-basis: 20%;
                align-content: space-between;
            }
            .bigCard {
                //min-width: 300px;
                max-width: 300px;
                height: 550px;
                .chartProdutos {
                    max-width: 280px;
                    height: 325px;
                }
                .descriptionProdutos {
                    height: 150px;
                    max-width: 280px;
                    padding-left: 40px;
                    padding-right: 50px;
                    .productUnity {
                        display: flex;
                        justify-content: space-between;
                        max-width: 200px;
                        margin-bottom: 12px;
                        .productDescription {
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                        }
                        .productValue {
                            color: #678ead;
                        }
                    }
                }
                .chartCaptacaoPorAssessor {
                    max-width: 250px;
                    height: 450px;
                    margin-bottom: 30px;
                }
            }
            .soBigCard {
                //min-width: 300px;
                max-width: 650px;
                height: 350px;
                .chartCaptacaoVsReceitasContainer {
                    // max-width:  700px;
                    // width: 100%;
                    max-width: 700px;
                    height: 300px;
                    display: flex;
                    align-items: center;
                    .chartCaptacaoVsReceitas {
                        max-width: 450px;
                        height: 300px;
                    }
                    .chartCaptacaoVsReceitasDescription {
                        max-width: 200px;
                        height: 200px;
                        display: flex;
                        align-content: space-around;
                        flex-wrap: wrap;
                        .assessorUnity {
                            display: flex;
                            align-items: center;
                            max-width: 200px;
                            margin-bottom: 5px;
                            font-size: 14px;
                            font-weight: bold;
                            span {
                                padding-left: 10px;
                            }
                            i {
                                padding-left: 5px;
                            }
                        }
                    }
                }
                .chartReceitaPorAssessor {
                    // max-width: 700px;
                    width: 100%;
                    height: 370px;
                    margin-right: 15px;
                    margin-top: 15px;
                }
                .chartReceitaAssessor {
                    max-width: 700px;
                    height: 300px;
                    margin-right: 15px;
                }
            }
            .soBigCardHalf {
                //min-width: 300px;
                max-width: 300px;
                height: 350px;
                .containerAssessores {
                    max-width: 280px;
                    height: 300px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    // align-content: space-around;
                    overflow: auto;
                    .assessorUnity300k {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        max-width: 240px;
                        margin-bottom: 10px;
                        .ativacao300k {
                            color: #a3a0fb;
                            font-size: 20px;
                        }
                    }
                    .topAssessores {
                        display: flex;
                        // justify-content: space-around;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        max-width: 240px;
                        margin-bottom: 10px;
                        .assessorUnity {
                            display: flex;
                            align-items: center;
                            .nameAssessor {
                                padding-left: 20px;
                            }
                            i {
                                padding-left: 20px;
                            }
                        }
                    }
                    .divider {
                        margin-bottom: 10px;
                        max-width: 240px;
                    }
                    .dividerTopAssessores {
                        margin-bottom: 10px;
                        max-width: 240px !important;
                    }
                }
            }
            .centerChart {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .card {
            border-radius: 10px;
            padding: 10px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            // background-color: #f0f0f747;
            border: 0.1px solid rgb(226, 226, 226);
            display: block;
            margin: 0;
        }
        .card:hover {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        }
        .card-description {
            font-weight: bold;
            font-size: 18px;
            margin-left: 8px;
            display: block;
        }
        .card-value {
            font-weight: bold;
            text-align: center;
            font-size: 17px;
            max-width: 150px;
            min-width: 100px;
        }
        .netSquad {
            width: 100px;
            position: relative;
            top: 4px;
            // transform: scale(0.8);
        }
        .spaceChart {
            margin-top: -2px;
            width: 100px;
            position: relative;
            top: 4px;
            justify-content: space-around;
            //max-width: 100px;
            //height: 100px;
            // background-color: black;
        }
        h3 {
            font-size: 25px;
            color: #43425d !important;
            margin: 0;
            text-align: center;
        }
        h5 {
            font-size: 15px;
            color: #43425d !important;
            margin: 0;
            text-align: center;
        }

        @media (max-width: 1680px) {
            .containerCards {
                //overflow: auto;
                // padding-left: 50px;
            }
            h3 {
                font-size: 22px;
                color: #43425d !important;
            }
            h5 {
                font-size: 15px;
                color: #43425d !important;
            }
        }
        .containerCards {
            margin-bottom: 50px;
        }
        @media (max-width: 1450px) {
            .rowCards {
                // margin-right: 50px;
            }
            h3 {
                font-size: 18px;
            }
            .header-dashboard {
                height: 90px;
            }
        }
        @media (max-width: 1200px) {
            h3 {
                font-size: 16px;
            }
            h5 {
                font-size: 12px;
            }
            .header-dashboard {
                height: 70px;
            }
        }
        @media (max-width: 600px) {
            h3 {
                font-size: 14px;
            }
        }
    }

    // Dash Lider
    .s-1 {
        grid-area: s-1;
    }
    .s-2 {
        grid-area: s-2;
    }
    .s-3 {
        grid-area: s-3;
    }
    .s-4 {
        grid-area: s-4;
    }
    .s-5 {
        grid-area: s-5;
    }
    .s-6 {
        grid-area: s-6;
    }
    .s-7 {
        grid-area: s-7;
    }
    .sm-1 {
        grid-area: sm-1;
    }
    .m-1 {
        grid-area: m-1;
    }
    .m-2 {
        grid-area: m-2;
    }
    .mc-1 {
        grid-area: mc-1;
    }
    .mc-2 {
        grid-area: mc-2;
    }
    .b-1 {
        grid-area: b-1;
    }
    .b-2 {
        grid-area: b-2;
    }
    .bg-1 {
        grid-area: bg-1;
    }
    .bg-2 {
        grid-area: bg-2;
    }

    // Dash Assessor
    .adv-s-1 {
        grid-area: adv-s-1;
    }
    .adv-s-2 {
        grid-area: adv-s-2;
    }
    .adv-s-2 {
        grid-area: adv-s-2;
    }
    .adv-s-3 {
        grid-area: adv-s-3;
    }
    .adv-s-4 {
        grid-area: adv-s-4;
    }
    .adv-s-5 {
        grid-area: adv-s-5;
    }
    .adv-s-6 {
        grid-area: adv-s-6;
    }
    .adv-s-7 {
        grid-area: adv-s-7;
    }
    .adv-s-8 {
        grid-area: adv-s-8;
    }
    .adv-s-9 {
        grid-area: adv-s-9;
    }
    .adv-s-10 {
        grid-area: adv-s-10;
    }
    .adv-m-1 {
        grid-area: adv-m-1;
    }
    .adv-b-1 {
        grid-area: adv-b-1;
    }
    .adv-b-2 {
        grid-area: adv-b-2;
    }
    .adv-xl-1 {
        grid-area: adv-xl-1;
    }
    .adv-xl-2 {
        grid-area: adv-xl-2;
    }
    .nothing {
        grid-area: nothing;
    }

    .containerCardsAssessor {
        padding: 1%;
        display: grid;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 25% 25% 10% 15% 25%;
        // height: 1500px;
        grid-template-rows: auto;
        grid-template-areas: 
        // Col 1    // Col 2    // Col 3                // Col 4

            "adv-s-1    adv-b-1     adv-b-1     adv-b-1     adv-s-4"
            "adv-m-1    adv-b-1     adv-b-1     adv-b-1     adv-s-5"
            "adv-m-1    adv-b-2     adv-b-2     adv-b-2     adv-s-6"
            "adv-s-9    adv-b-2     adv-b-2     adv-b-2     adv-s-7"
            "adv-s-10    adv-b-2     adv-b-2     adv-b-2     adv-s-8"
            "adv-xl-1   adv-xl-1    adv-xl-1    adv-xl-2    adv-xl-2"
            "adv-xl-1   adv-xl-1    adv-xl-1    adv-xl-2    adv-xl-2";
        // "nothing   adv-xl-2    adv-xl-2    adv-xl-2    nothing"
        // "nothing   adv-xl-2    adv-xl-2    adv-xl-2    nothing";

        .item {
            margin: 20px;
            .card-description {
                font-weight: bold;
                font-size: 18px;
                margin-left: 8px;
                display: block;
            }
            .chartAndValue {
                display: flex;
                height: 80%;
                align-items: center;
                margin: 0px 10px;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }

        @media (max-width: 1650px) {
            .soBigCard {
                height: 91%;
            }
        }

        .adv-s-5,
        .adv-s-6,
        .adv-s-7,
        .adv-s-8,
        .adv-s-9,
        .adv-s-10 {
            min-height: 150px;
        }
    }

    .soBigCard {
        //min-width: 300px;
        // max-width: 650px;
        height: 350px;
        .chartReceitaPorAssessor {
            // max-width: 700px;
            width: 105%;
            height: 370px;
            position: relative;
            top: -30px;
            right: 30px;

            //margin-right: 15px;
            //margin-top: 15px;
        }
        .chartNpsPorAssessor {
            width: 105%;
            height: 400px;
            position: relative;
            top: -18px;
            right: 12px;
        }
        .chartReceitaAssessor {
            // max-width: 700px;
            height: 300px;
            position: relative;
            top: 15px;
            .chartCaptacao {
                height: 400px !important;
            }
        }
        .chartCaptacaoAssessor {
            // max-width: 700px;
            @media (max-width: 1600px) {
                height: 300px;
            }
            height: 300px;
            position: relative;
            top: 15px;
        }
    }

    .chartProdutos {
        // max-width: 280px;
        height: 200px;
    }
    .descriptionProdutos {
        // height: 150px;
        // max-width: 280px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // padding: 0px 5%;
        .productUnity {
            display: flex;
            justify-content: space-between;
            // width: 250px;
            // margin-left: auto;
            // margin-right: auto;
            margin-bottom: 12px;
            .productDescription {
                display: flex;
                align-items: center;
                font-weight: bold;
            }
            .productValue {
                color: #678ead;
            }
        }
    }

    .centerChart {
        // height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bigCardLastRow {
        height: 400px;
        .funilComercial {
            width: 100%;
            position: relative;
            left: 5%;
            top: 25px;
            height: 100%;
        }
    }

    .soBigCardLastRow {
        // max-width: 800px;
        // height: 350px;
        .topComercial {
            width: 95%;
            max-height: 345px;
            overflow-y: auto;
            margin-left: auto;
            margin-right: auto;
            table {
                height: 360px;
            }
            .assessorUnity {
                display: flex;
                align-items: center;
                .nameAssessor {
                    padding-left: 20px;
                    font-weight: bold;
                }
                i {
                    padding-left: 20px;
                }
            }
        }
        .centerText {
            text-align: center;
        }
    }

    .containerCards {
        padding: 1%;
        display: grid;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 25% 25% 15% 10% 25%;
        // height: 1500px;
        grid-template-rows: auto;
        grid-template-areas:
            "s-1    m-2     m-2     m-2     s-4"
            "mc-2   m-2     m-2     m-2     s-5"
            "mc-2   mc-1    mc-1   mc-1     e"
            "s-6    mc-1    mc-1   mc-1     e"
            "z      v       v       m       e"
            "z      b-2     b-2     b-2     sm-1"
            "z      b-2     b-2     b-2     sm-1"
            "bg-1   bg-1    bg-1    bg-2    bg-2"
            "bg-1   bg-1    bg-1    bg-2    bg-2";
        // gap: 10px;
        .item {
            margin: 20px;
            .card-description {
                font-weight: bold;
                font-size: 18px;
                margin-left: 8px;
                display: block;
            }
            .chartAndValue {
                display: flex;
                height: 80%;
                align-items: center;
                margin: 0px 10px;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }

        .soBigCardHalf {
            //min-width: 300px;
            // max-width: 300px;
            // height: 350px!important;
            max-height: 350px;
            .containerAssessores {
                // max-height: 260px;
                @media (min-width: 1450px) {
                    height: 90%;
                }
                @media (max-width: 1450px) {
                    height: 80%;
                }
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                overflow: auto;
                .assessorUnity300k {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;
                    max-width: 240px;
                    margin-bottom: 10px;
                    .ativacao300k {
                        color: #a3a0fb;
                        font-size: 20px;
                    }
                }
            }
        }

        .topAssessores {
            display: flex;
            // justify-content: space-around;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            max-width: 240px;
            margin-bottom: 10px;
            .assessorUnity {
                display: flex;
                align-items: center;
                .nameAssessor {
                    padding-left: 20px;
                }
                i {
                    padding-left: 20px;
                }
            }
        }
        .divider {
            margin-bottom: 10px;
            max-width: 240px;
        }

        // .bigCard {
        //     //min-width: 300px;
        //     max-width: 300px;
        //     // height: 550px;
        //     .chartCaptacaoPorAssessor {
        //         max-width: 250px;
        //         // height: 400px;
        //         margin-bottom: 30px;
        //     }
        // }

        .chartCaptacaoVsReceitasContainer {
            // max-width:  700px;
            // width: 100%;
            //max-width: 700px;
            //width: 100%;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            .chartCaptacaoVsReceitas {
                @media (min-width: 1250px) {
                    width: 70%;
                }
                @media (max-width: 1250px) {
                    width: 100%;
                }

                height: 300px;
            }
            .chartCaptacaoVsReceitasDescription {
                @media (max-width: 1250px) {
                    display: none;
                }
                display: flex;
                width: 30%;
                max-height: 260px;
                overflow: auto;
                flex-direction: column;
                .assessorUnity {
                    display: flex;
                    align-items: center;
                    max-width: 200px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: bold;
                    span {
                        padding-left: 10px;
                    }
                    i {
                        padding-left: 5px;
                    }
                }
            }
        }

        .chartCaptacaoPorAssessor {
            height: 460px;
            max-width: 290px;
            position: relative;
            top: 20px;
            left: 7px;
        }

        .s-5,
        .s-6,
        .s-7 {
            min-height: 160px;
        }
    }

    // For mobile
    .containerCards-mb {
        padding: 0%;
        display: grid;
        // width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 100%;
        // height: 1500px;
        grid-template-rows: auto;
        grid-template-areas: 
        // Col 1    // Col 2

            "s-1"
            "s-4"
            "s-2"
            "s-3"
            "m-2"
            "mc-1"
            "b-1"
            "b-2"
            "s-5"
            "s-6"
            "s-7"
            "sm-1"
            "m-1"
            "mc-2"
            "bg-1"
            "bg-2";

        .item {
            margin: 13px 12px;
            .card-description {
                font-weight: bold;
                font-size: 18px;
                margin-left: 8px;
                display: block;
            }
            .chartAndValue {
                display: flex;
                height: 80%;
                align-items: center;
                margin: 0px 10px;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }

        .soBigCardHalf {
            //min-width: 300px;
            // max-width: 300px;
            // height: 350px!important;
            max-height: 350px;
            .containerAssessores {
                // max-height: 260px;
                @media (min-width: 1450px) {
                    height: 90%;
                }
                @media (max-width: 1450px) {
                    height: 90%;
                }
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                overflow: auto;
                .assessorUnity300k {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;
                    // max-width: 240px;
                    margin-bottom: 10px;
                    .ativacao300k {
                        color: #a3a0fb;
                        font-size: 20px;
                    }
                }
            }
        }

        .topAssessores {
            display: flex;
            // justify-content: space-around;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            max-width: 240px;
            margin-bottom: 10px;
            .assessorUnity {
                display: flex;
                align-items: center;
                .nameAssessor {
                    padding-left: 20px;
                }
                i {
                    padding-left: 20px;
                }
            }
        }
        .divider {
            margin-bottom: 10px;
            // max-width: 240px;
        }

        // .bigCard {
        //     //min-width: 300px;
        //     max-width: 300px;
        //     // height: 550px;
        //     .chartCaptacaoPorAssessor {
        //         max-width: 250px;
        //         // height: 400px;
        //         margin-bottom: 30px;
        //     }
        // }

        .chartCaptacaoVsReceitasContainer {
            // max-width:  700px;
            // width: 100%;
            //max-width: 700px;
            //width: 100%;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            .chartCaptacaoVsReceitas {
                @media (min-width: 1250px) {
                    width: 70%;
                }
                @media (max-width: 1250px) {
                    width: 100%;
                }

                height: 300px;
            }
            .chartCaptacaoVsReceitasDescription {
                @media (max-width: 1250px) {
                    display: none;
                }
                display: flex;
                width: 30%;
                flex-direction: column;
                .assessorUnity {
                    display: flex;
                    align-items: center;
                    max-width: 200px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: bold;
                    span {
                        padding-left: 10px;
                    }
                    i {
                        padding-left: 5px;
                    }
                }
            }
        }

        .chartCaptacaoPorAssessor {
            height: 460px;
            max-width: 95%;
            position: relative;
            // top: 20px;
            left: 7px;
        }

        .s-5,
        .s-6,
        .s-7 {
            min-height: 160px;
        }
    }

    // For mobile
    .containerCardsAssessor-mb {
        padding: 0%;
        display: grid;
        // width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 100%;
        // height: 1500px;
        grid-template-rows: auto;
        grid-template-areas: 
        // Col 1    // Col 2

            "adv-s-1"
            "adv-s-4"
            "adv-s-2"
            "adv-s-3"
            "adv-b-1"
            "adv-b-2"
            "adv-m-1"
            "adv-s-5"
            "adv-s-6"
            "adv-s-7"
            "adv-s-8"
            "adv-s-9"
            "adv-s-10"
            "adv-xl-1"
            "adv-xl-2";

        .item {
            margin: 13px 12px;
            .card-description {
                font-weight: bold;
                font-size: 18px;
                margin-left: 8px;
                display: block;
            }
            .chartAndValue {
                display: flex;
                height: 80%;
                min-height: 90px;
                align-items: center;
                margin: 0px 10px;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }

        .soBigCardHalf {
            //min-width: 300px;
            // max-width: 300px;
            // height: 350px!important;
            max-height: 350px;
            .containerAssessores {
                // max-height: 260px;
                @media (min-width: 1450px) {
                    height: 90%;
                }
                @media (max-width: 1450px) {
                    height: 90%;
                }
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                overflow: auto;
                .assessorUnity300k {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;
                    // max-width: 240px;
                    margin-bottom: 10px;
                    .ativacao300k {
                        color: #a3a0fb;
                        font-size: 20px;
                    }
                }
            }
        }

        .topAssessores {
            display: flex;
            // justify-content: space-around;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            max-width: 240px;
            margin-bottom: 10px;
            .assessorUnity {
                display: flex;
                align-items: center;
                .nameAssessor {
                    padding-left: 20px;
                }
                i {
                    padding-left: 20px;
                }
            }
        }
        .divider {
            margin-bottom: 10px;
            // max-width: 240px;
        }

        // .bigCard {
        //     //min-width: 300px;
        //     max-width: 300px;
        //     // height: 550px;
        //     .chartCaptacaoPorAssessor {
        //         max-width: 250px;
        //         // height: 400px;
        //         margin-bottom: 30px;
        //     }
        // }

        .chartCaptacaoVsReceitasContainer {
            // max-width:  700px;
            // width: 100%;
            //max-width: 700px;
            //width: 100%;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            .chartCaptacaoVsReceitas {
                @media (min-width: 1250px) {
                    width: 70%;
                }
                @media (max-width: 1250px) {
                    width: 100%;
                }

                height: 300px;
            }
            .chartCaptacaoVsReceitasDescription {
                @media (max-width: 1250px) {
                    display: none;
                }
                display: flex;
                width: 30%;
                flex-direction: column;
                .assessorUnity {
                    display: flex;
                    align-items: center;
                    max-width: 200px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: bold;
                    span {
                        padding-left: 10px;
                    }
                    i {
                        padding-left: 5px;
                    }
                }
            }
        }

        .chartCaptacaoPorAssessor {
            height: 460px;
            max-width: 95%;
            position: relative;
            // top: 20px;
            left: 7px;
        }

        .s-5,
        .s-6,
        .s-7 {
            min-height: 160px;
        }
    }
}

.side-nav {
    background-color: $darkerPrimary;
    color: #fff !important;
    margin: 0 !important;
    //width: 16.6%;
    width: 240px;
    position: fixed;
    height: 100%;
    overflow: auto;

    a {
        color: #fff !important;
        font-size: 18px !important;
    }

    li {
        display: block;
        transition: 0.1s;
        line-height: normal !important;
        &:hover,
        &.active {
            background-color: $darkPrimary;
            border-left: 0.25em solid $primary;
        }
        &.header {
            padding: 1.5em;
            // margin-bottom: 1em;
            background-color: $darkPrimary;

            // border-bottom: 1px solid #fff;
            //my changes
            .marcologo-logged {
                background-image: url("./Assets/Images/logo-marcolab.png");
                background-size: cover;
                background-repeat: no-repeat;
                margin-left: auto;
                margin-right: auto;
                height: 25px;
                width: 140px;
            }
            &:hover {
                background-color: $darkPrimary;
                border-left: 0 !important;
            }
        }
        a {
            display: block;
            padding: 0.7em 0.8em !important;
            text-transform: none !important;
            height: 70px !important;
            .textSideNav {
                font-size: 16px;
            }
            .icon {
                margin-right: 1em;
                i {
                    position: relative;
                    top: 6px;
                }
            }
        }
    }
    i.material-icons {
        position: relative;
        top: 5px;
    }
}

.thumb {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #323232;
    display: inline-block;
    margin-left: 0.5em;
}

.box {
    background-color: #fff;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
}

.clientes {
    .header {
        border-bottom: 1px solid $lighterGray;
        .row {
            margin-bottom: 0;
        }
    }
    .input-field {
        margin: 0;
    }
}

.form-separador {
    margin-bottom: 2em;
    display: block;
}

.input-erros {
    background-color: #efb8b8;
    border-radius: 1em;
    transition: 0.25s;
    margin-bottom: 2em;
    li {
        font-size: 0.875em;
    }
}

.hide {
    display: none;
}

/*
select {
    display: block;
}
*/

#modalReuniao {
    padding: 1%;
    max-width: 750px;
    width: 90%;
    max-height: 85%;
    .cards-choose-reuniao {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 10px;

        @media only screen and (max-width: 650px) {
            flex-direction: column;

            .card-unity-choose-reuniao {
                width: 90% !important;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .card-unity-choose-reuniao {
            height: 350px;
            border: 1px solid #715b9c;
            border-radius: 10px;
            width: 48%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;

            transition: box-shadow 0.3s;
            &:hover {
                // color: #fff !important;
                box-shadow: 0 0 30px rgba(33, 33, 33, 0.15);
                cursor: pointer;
            }
        }
    }
}

#modalAdm {
    width: 600px !important;
    margin: 0 auto;
    background-color: #fff;

    .modal-footer {
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
}
.modal {
    border-radius: 10px;
    .modal-data {
        width: 500px;
        margin: 0 auto;
    }
    &.show {
        display: block !important;
        &.modal {
            width: 600px;
            height: 250px;
            margin-top: 50vh;
            top: -125px;
        }
    }
}

#modalRegistroAtividade {
    height: 400px !important;
    width: 30% !important;
}
#modalQualificar {
    width: 60%;
    // height: 1000px !important;
}
/*
.modal {
    width: auto;
    align-items: center;
    min-height: 100vh;
    max-height: 100%;
    background-color: transparent;
    top: 0;

    .modal-footer {
        .btn {
            margin-right: 1em;
        }
    }

    &.show {
        display: flex;
    }

    .modal-data {
        width: 500px;
        margin: 0 auto;
        background-color: #fff;
    }

    .bg-modal {
        background-color: #000;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.2;
    }
}
*/

.admin-actions {
    a {
        margin-right: 0.5em;
    }
}

.center-all {
    display: flex !important;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.full-height {
    height: 100vh;
}

.space-between {
    justify-content: space-between;
}

.dflex {
    display: flex;
    align-items: center;
}

.input-changes {
    margin: 0 0 8px 0;
}

.top-nav {
    background-color: #fff;
    padding: 1em 0;
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.062);
    .row {
        margin-bottom: 0;
    }
    .actions {
        display: block;
        text-align: right;
        margin-right: 1em;
        border-right: 1px solid $lightGray;

        i {
            margin-right: 0.5em;
            cursor: pointer;
        }
    }

    .input-field {
        margin: 0;
        input {
            border-bottom: 0 !important;

            &:hover,
            &:active,
            &:focus {
                border-bottom: 0 !important;
            }
        }
    }

    .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
            top: 0 !important;
            margin-left: 0.5em;
        }
    }

    .center-align {
        display: flex;
        height: 50px;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
    }

    .center-align-left {
        display: flex;
        height: 50px;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
    }
}

.admin-content {
    padding: 2em;
    transition: 0.15s;
    background-color: #fff;
    -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.header-steps {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // height: 100px;
    width: 100%;
    //min-width: 1100px;
    .tabs {
        display: flex;
        align-items: center;
        width: auto;
    }

    .tabs .tab a:focus,
    .tabs .tab a:focus.active {
        background-color: rgba(255, 255, 255, 0) !important;
        outline: none;
    }

    .open-profile {
        i {
            margin-right: 3px;
            margin-bottom: 1.5px;
        }
    }
}

.header-description {
    display: flex;
    align-items: center;
    margin-left: 50px;
    height: 100px;
    //width: 100%;
}

ul.tabs.admin {
    .tab {
        text-transform: none;
        height: 100%;
    }
    .tab a:focus.active {
        background-color: rgba(255, 255, 255, 0) !important;
    }
    a {
        cursor: pointer;
        color: #6d3899 !important;
        font-weight: bold;

        &.active,
        &:hover {
            color: #fff !important;
            background-color: #6d3899 !important;
            .dot {
                background: #6d3899;
                color: white;
            }
            .dot.stepCompleted {
                background: #6d3899;
                border: 2px solid #6d3899;
            }
        }
        .dot {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            padding-top: 2px;
            background: #fff;
            border: 2px solid #6d3899;
            color: #6d3899;
            text-align: center;
            font-size: 13px;
            display: block;
        }
        // Creating the steps lines
        .line-right {
            margin: 0;
            padding: 0;
            position: relative;
            width: 45px;
            height: 2px;
            bottom: 51px;
            left: 55px;
            border: none;
            background-color: #6d3899a8;
            &.firstOrLast {
                bottom: 50px;
            }
            &.completed {
                background-color: #34c38f;
            }
        }
        .line-left {
            margin: 0;
            padding: 0;
            position: relative;
            width: 45px;
            height: 2px;
            bottom: 49px;
            right: 55px;
            border: none;
            background-color: #6d3899a8;
            &.firstOrLast {
                bottom: 50px;
            }
            &.completed {
                background-color: #34c38f;
            }
        }
        .dot.stepCompleted {
            background: #34c38f;
            border: 2px solid #34c38f;
            i {
                color: white;
            }
        }
        i {
            font-size: 20px;
            color: #6d3899;
        }
        h6 {
            margin-top: 10px;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .indicator {
        display: none;
        background-color: #6d38991f !important;
        height: 2px;
    }
    li {
        height: 100%;
        border-radius: 10px;
        a {
            font-size: 15px !important;
        }
    }
}

ul.tabs.primeira-reuniao {
    height: 100px;
    @media (min-width: 1360px) {
        height: 100px;
    }
    @media (max-width: 1360px) {
        height: 85px;
    }
    .tab {
        text-transform: none;
        height: 100%;
    }
    .tab a:focus.active {
        background-color: rgba(255, 255, 255, 0) !important;
    }
    a {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
        margin-top: 5px;
        justify-content: center;
        @media (min-width: 1360px) {
            width: 150px !important; //
        }
        @media (max-width: 1360px) {
            width: 100px !important; //
        }
        padding: 0 !important; //
        color: #6d3899a9 !important;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            color: #6d3899 !important;
        }
        &.active {
            color: #6d3899 !important;
            .dot {
                background: #6d3899;
                color: white;
            }
            .dot.stepCompleted {
                background: #6d3899;
                border: 2px solid #6d3899;
            }
        }
        .dot {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            // padding-top: 2px;
            background: #fff;
            border: 2px solid #6d3899;
            color: #6d3899;
            text-align: center;
            font-size: 13px;
            display: block;
            @media (min-width: 1360px) {
                width: 30px;
            }
            @media (max-width: 1360px) {
                width: 24px;
                height: 24px;
            }
        }
        // Creating the steps lines
        .line-right {
            margin: 0;
            padding: 0;
            position: relative;
            // width: 45px;
            @media (min-width: 1360px) {
                width: 45px;
                left: 55px;
                // bottom: 51px;
                bottom: 55px;
                &.firstOrLast {
                    bottom: 54px;
                }
            }

            @media (max-width: 1360px) {
                width: 20px;
                left: 40px;
                bottom: 50px;
                &.firstOrLast {
                    bottom: 49px;
                }
            }
            height: 2px;
            border: none;
            background-color: #6d3899a8;

            &.completed {
                background-color: #34c38f;
            }
        }
        .line-left {
            margin: 0;
            padding: 0;
            position: relative;
            @media (min-width: 1360px) {
                width: 45px;
                bottom: 53px;
                right: 55px;
                &.firstOrLast {
                    bottom: 54px;
                }
            }

            @media (max-width: 1360px) {
                width: 20px;
                bottom: 48px;
                right: 40px;
                &.firstOrLast {
                    bottom: 49px;
                }
            }
            height: 2px;
            border: none;
            background-color: #6d3899a8;

            &.completed {
                background-color: #34c38f;
            }
        }
        .dot.stepCompleted {
            background: #34c38f;
            border: 2px solid #34c38f;
            i {
                color: white;
            }
        }
        i {
            @media (min-width: 1360px) {
                font-size: 20px;
            }
            @media (max-width: 1360px) {
                font-size: 15px;
            }
            color: #6d3899;
        }

        @media (min-width: 1360px) {
            h6 {
                margin-top: 10px;
                font-weight: 600;
                font-size: 13px;
                height: 20px;
            }
        }
        @media (max-width: 1360px) {
            h6 {
                margin-top: 10px;
                font-weight: 600;
                font-size: 12px;
                height: 20px;
            }
        }
    }
    .indicator {
        display: none;
        background-color: #6d38991f !important;
        height: 2px;
    }
    li {
        height: 100%;
        border-radius: 10px;
        a {
            font-size: 15px !important;
        }
    }
}

table {
    tr:last-child {
        border-bottom: 0 !important;
    }

    thead {
        border-bottom: 1px solid $lightGray;
    }
}

.no-overflow {
    overflow: hidden;
}

.row {
    margin-bottom: 0px;
}

#main-iframe {
    width: 100%;
    // height: 300px;
    height: 75vh;
    // margin-top: 25px;
    margin-right: auto;
    margin-left: auto;
    //min-height: 700px;
    //min-width: 700px;
    border: none;
}

.text-bold {
    font-weight: bold !important;
}

.listaCliente {
    .head-infos-clientes {
        margin-right: 5.1%;
        background-color: white;
        min-width: 300px;
        border-radius: 5px;
        font-size: 20px;
        height: 60px;
        .center-all {
            justify-content: space-evenly;
            height: 100%;
        }
        i {
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
}

.perfil-cliente {
    margin: 10px;
    .geralInfos {
        background-color: white;
        padding: 20px;
        font-size: 13px;
        border-radius: 5px;
        margin-top: 30px;
        a {
            color: rgba(0, 0, 0, 0.585);
            font-weight: bold;
            text-transform: none;
            font-size: 13px;
            &:hover {
                color: #55277b;
            }
            &.active {
                color: #55277b;
                font-weight: bold;
            }
            &:focus.active {
                background-color: #55277b08;
            }
        }
        .tabs .indicator {
            background-color: #55277b;
        }
        .tab {
            &:hover {
                background-color: #55277b08;
            }
        }

        .input-field .select-wrapper .dropdown-content li > a,
        .input-field .select-wrapper .dropdown-content li > span {
            font-size: 14px;
        }

        .select-wrapper input.select-dropdown {
            font-size: 14px;
        }

        .notasAndAtividadesInput {
            position: relative;
            right: 7px;
            bottom: 14px;
        }
    }
    .statusRow {
        margin-top: 25px;
        .status {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 85%;
            background-color: #a3a0f4;
            padding: 5px;
            padding-left: 15px;
            border-radius: 15px;
            min-width: 145px;
        }
        .wonOrLost {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid #a3a0f461;
            padding: 5px;
            border-radius: 15px;
            min-width: 145px;
            .division {
                color: #a3a0f4b6;
            }
            .options {
                display: flex;
                align-items: center;
                i {
                    margin-left: 5px;
                }
                .lost {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .perfilClient {
        i {
            margin-top: 30px;
        }
    }
    h3 {
        font-size: 20px;
        margin-top: 0px;
    }
    h4 {
        font-size: 14px;
        color: #a2a2bb;
        font-weight: normal;
        margin: 15px;
    }
    .divider {
        margin: 15px 0px;
    }
    .value {
        h4 {
            font-size: 16px;
        }
    }

    .tabs {
        a {
            padding: 0 3px;
        }
    }

    .modal-content {
        .input-field {
            margin-top: 30px;
        }
    }

    .open-profile {
        a:hover {
            text-decoration: underline;
        }
        i {
            position: relative;
            top: 7px;
            left: 10px;
        }
    }

    .change-price {
        position: relative;
        top: -38px;
        left: 70px;
    }

    .date-activity {
        background-color: #eef1fd;
        color: #9774b4;
        max-width: 80px;
        padding: 5px;
        line-height: 13px;
        border-radius: 2px;
    }
}

.modalChangeStatus {
    width: 90% !important;
    height: 50% !important;
    min-height: 450px;
    @media (min-width: 600px) {
        width: 500px !important;
    }
}

#modalAdicionarCliente {
    width: 500px;
    max-height: 94%;
    // border-radius: 10px;
    h3 {
        font-size: 20px;
        color: #43425d;
        margin-bottom: 0px;
    }
    h6 {
        font-size: 13px;
        color: #6c6b80;
        padding-bottom: 20px;
    }
}

#modalRedirectToPlanoDeVoo {
    max-width: 600px;
    max-height: 94%;
    border-radius: 10px;
    h3 {
        font-size: 20px;
        color: #43425d;
        margin-bottom: 0px;
    }
    h6 {
        font-size: 13px;
        color: #6c6b80;
        padding-bottom: 20px;
    }
    .footer-buttons {
        margin: 30px 0px;
        text-align: center;
        a {
            margin: 10px 0px;
        }
        .submit-button {
            background-color: #512178;
        }
        .back-button {
            background-color: white;
            color: #512178;
            border: 1px solid #512178;
            font-size: 12px;
            box-shadow: none;
        }
    }
}

.space-between-two-lines {
    margin: 10px 0px;
}

.space-between-two-lines-big {
    margin: 15px 0px;
}

.space-bottom {
    margin-bottom: 20px;
}

.btn-hover {
    cursor: pointer;
}

.green {
    background-color: #34c38f !important;
}

#origem-renda {
    position: relative;
    bottom: 25px;
}

.tooltip {
    position: absolute;
    bottom: 40px;
    right: 5px;
}

.marcologo-logged {
    background-image: url("./Assets/Images/logo-marcolab.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 25px;
    width: 140px;
}

.black-text {
    color: black;
}

.fc-daygrid-day-events {
    font-size: 11px !important;
    line-height: 20px;
}
