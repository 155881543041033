.login {
    h2 {
        font-weight: bold;
        color: #512178;
        font-size: 2em;
        margin-bottom: 0.25em;
        margin-top: 50px;
    }

    h3 {
        color: #adadad;
        font-weight: normal;
        margin: 0 0 2em;
        font-size: 1em;
    }
}

[data-component="first-meeting"] {
    .content {
        padding-top: 0px;
        padding-right: 0px;
    }
}

[data-component="registration"] {
    margin: 0;

    @media (min-width: 1200px) {
        .overflow-scroll {
            overflow: auto;
            overflow-x: hidden;
        }
    }

    .logo-lab {
        @media (max-width: 1650px) {
            position: relative;
            left: 50%;
            margin-left: -100px;
            margin-bottom: 10%;
            margin-top: 14%;
            width: 200px;
            height: 128px;
        }
    }
}

#modalTermosAll {
    :hover {
        color: #512178 !important;
        text-decoration: underline;
    }
}

#modalTermos {
    color: #9e9e9e !important;
    text-decoration: underline;
}

#buttonModal {
    color: black;
}

.report-container {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.powerbi-report {
    width: 100%;
    margin-top: 20px;
    height: 85vh;
    border: none;
}

.powerbi-report iframe {
    border: 0;
    background-color: #f0f0f7;
}

.backgroundContainer {
    background-color: #f0f0f7 !important;
}

.reports {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
    margin-top: 20px;
    align-items: center;
}

.report {
    flex-basis: 20%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 5px;
}

.report:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}